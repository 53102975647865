import React, { FunctionComponent } from 'react';
import { withStyles, createStyles, WithStyles } from '@material-ui/styles';
import withWidth, { isWidthUp, isWidthDown, WithWidth } from '@material-ui/core/withWidth'
import CardsDeck from '../components/cards/CardsDeck'
import MultistreamCard from '../components/cards/MultistreamCard'
import GameCard from '../components/cards/GameCard'
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { DocumentScrollbars } from '@multistream/utils';

const styles = () => createStyles({
    sectionTitle: {
        fontSize: '1.7rem'
    }
});

type SearchResultsProps = WithStyles<typeof styles> & WithWidth & {};

const SearchResults: FunctionComponent<SearchResultsProps> = (props) => {
    const { classes } = props;
    const { width } = props;

    const currentSectionMargin = isWidthUp('md', width) ? 16 : isWidthDown('xs', width) ? 4 : 8;

    return (
        <Box>
            <Typography variant='h4' align='center'>
                Search results
            </Typography>

            <Box mt={8} pl={currentSectionMargin}>
                <Typography variant="h4" className={classes.sectionTitle}>
                    <b>Games</b>
                </Typography>
            </Box>

            <CardsDeck id='searchedGames' render={card => <GameCard key={card.id} {...card} />} maxItems={isWidthUp('md', width) ? 7 : 6} />

            <Box mt={8} pl={currentSectionMargin}>
                <Typography variant="h4" className={classes.sectionTitle}>
                    <b>Multistreams</b>
                </Typography>
            </Box>

            <CardsDeck id='searchedMultistreams' render={card => <MultistreamCard keyId={card.key} {...card} />} />

            <DocumentScrollbars forceYScroll />
        </Box>
    );
};

export default withWidth()(withStyles(styles)(SearchResults));
