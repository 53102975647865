import { action } from '@multistream/utils';
import axios from 'axios';
import { createMultistreamUrl } from '../utils/functions/playerUrlCreators';
import { VariantType } from 'notistack';
import { Notification } from '../types/notification';
import { AppDispatch } from '../types/actionTypes';
import { Auth0ContextInterface } from '@auth0/auth0-react';
import { getRequestAuthConfig } from '@multistream/utils';
import { SiteFlags } from '../types/siteFlags';
import { refreshCards } from './cardsActions';
import { SiteState } from '../types/siteState';

export const types = {
    NOTIFY: 'site/NOTIFY',
    REMOVE_NOTIFICATION: 'site/REMOVE_NOTIFICATION',
    UPDATE_SITE_FLAGS: 'site/UPDATE_SITE_FLAGS'
};


export const createMultistream = (authContext: Auth0ContextInterface, title: string, description: string, isPrivate: boolean, redirectCallback: (url: string) => void) => {
    return async (dispatch: AppDispatch) => {
        try {
            const requestConfig = await getRequestAuthConfig(authContext);
            const response = await axios.post('/api/multistreams/create', { title, description, isPrivate }, requestConfig);
            var multistreamKey = response.data;
            var multistreamUrl = createMultistreamUrl(multistreamKey, title);
            redirectCallback(multistreamUrl);
        }
        catch (error) {
            dispatch(notify('Request failed: cannot create multistream', 'error'));
            console.error(error);
        }
    };
};

export const sendFeedback = (authContext: Auth0ContextInterface, email: string, message: string) => {
    return async (dispatch: AppDispatch) => {
        try {
            dispatch(sendFeedbackInProgess());
            const requestConfig = await getRequestAuthConfig(authContext);
            await axios.post('/api/site/contact', { message, email }, requestConfig);
            dispatch(notify('Feedback sent', 'success'));
        }
        catch (error) {
            dispatch(notify('Request failed: cannot send feedback', 'error'));
            console.error(error);
        }

        dispatch(sendFeedbackInProgess(false));
    };
};

export const reportFrontendError = (authContext: Auth0ContextInterface, error: Error) => {
    return async (_dispatch: AppDispatch, getState: () => SiteState) => {
        try {
            const requestConfig = await getRequestAuthConfig(authContext);
            await axios.post('/api/site/frontendError', { message: `${error.name}: ${error.message}`, stack: error.stack, state: getState() }, requestConfig);
        }
        catch (error) {
            console.error(error);
        }
    };
};

export const notify = (message: string, type: VariantType) => {
    const key: string = '' + (new Date().getTime() + Math.random());
    return action<Notification>(types.NOTIFY, { message, type, key });
}

export const removeNotification = (key: string) => action<string>(types.REMOVE_NOTIFICATION, key);

export const sendFeedbackInProgess = (value: boolean = true) => action<SiteFlags>(types.UPDATE_SITE_FLAGS, { isSendFeedbackInProgess: value });

export const changeMultistreamPrivacy = (authContext: Auth0ContextInterface, multistreamKey: string, isPrivate: boolean) => {
    return async (dispatch: AppDispatch) => {
        try {
            const requestConfig = await getRequestAuthConfig(authContext);
            await axios.post(`/api/multistreams/changePrivacy/${multistreamKey}?isPrivate=${isPrivate}`, {}, requestConfig);
            dispatch(refreshCards(authContext, 'userMultistreams'));

            dispatch(notify('Changed multistream to ' + (isPrivate ? 'private' : 'public'), 'success'));
        }
        catch (error) {
            dispatch(notify('Request failed: cannot change multistream privacy', 'error'));
            console.error(error);
        }
    };
}

export const removeMultistream = (authContext: Auth0ContextInterface, multistreamKey: string) => {
    return async (dispatch: AppDispatch) => {
        try {
            const requestConfig = await getRequestAuthConfig(authContext);
            await axios.delete(`/api/multistreams/remove/${multistreamKey}`, requestConfig);
            dispatch(refreshCards(authContext, 'userMultistreams'));

            dispatch(notify('Removed multistream', 'success'));
        }
        catch (error) {
            dispatch(notify('Request failed: cannot remove multistream', 'error'));
            console.error(error);
        }
    };
}
