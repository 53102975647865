import React, { FunctionComponent } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import FormState from '../utils/functions/FormState';
import { createMultistream } from '../actions/siteActions';
import { clearAllCards } from '../actions/cardsActions';
import { useHistory } from 'react-router-dom';
import { Container, Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { ButtonProgress } from '@multistream/utils';
import { useAuth0 } from '@auth0/auth0-react';

 const mapDispatchToProps = {
    createMultistream,
    clearAllCards
};

const connector = connect(null, mapDispatchToProps);

type CreateProps = ConnectedProps<typeof connector> & {};

const Create: FunctionComponent<CreateProps> = (props) => {
    const { createMultistream, clearAllCards } = props;
    const [isRequesting, setRequesting] = React.useState(false);
    const authContext = useAuth0();

    var formState = FormState.create({
        title: { required: true, maxLength: 255 },
        description: { maxLength: 255 },
        isPrivate: { value: false }
    });

    const history = useHistory();

    const createMultistreamClick = (formValues: any) => {
        setRequesting(true);
        createMultistream(authContext, formValues.title, formValues.description, formValues.isPrivate, (url) => history.push(url));
        clearAllCards();
    };

    return (
        <Container maxWidth='md'>
            <Typography variant='h4' align='center'>
                Create new multistream
            </Typography>
            <Box my={4}>
                <form autoComplete='off'>
                    <TextField
                        id='title'
                        label='Title'
                        value={formState.field('title').value}
                        onChange={formState.field('title').onChange}
                        error={formState.field('title').error}
                        helperText={formState.field('title').helperText}
                        margin='normal'
                        variant='outlined'
                        required
                        autoFocus
                        fullWidth
                    />
                    <TextField
                        id='description'
                        label='Description'
                        value={formState.field('description').value}
                        onChange={formState.field('description').onChange}
                        error={formState.field('description').error}
                        helperText={formState.field('description').helperText}
                        margin='normal'
                        variant='outlined'
                        fullWidth
                    />
                    <FormControlLabel control={
                        <Checkbox checked={formState.field('isPrivate').checked} onChange={formState.field('isPrivate').onChange} />
                        } label="Private multistream" />

                    <Box my={1} textAlign='center'>
                        <Button variant='contained' color='primary' onClick={formState.validateAndProcessOnClick(createMultistreamClick)} disabled={isRequesting}>
                            Create
                            {isRequesting && <ButtonProgress /> }
                        </Button>
                    </Box>
                </form>
            </Box>
        </Container>
    );
}

export default connector(Create);
