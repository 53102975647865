import React from 'react';

type FormDefinition = { [fieldId: string]: FieldDefinition};
type ReactFormDefinition = { [fieldId: string]: ReactFieldDefinition};

interface FieldDefinition {
    value?: any;
    required?: boolean;
    minLength?: number;
    maxLength?: number;
    regexFormat?: RegExp;
}

interface ReactFieldDefinition {
    value: any;
    errorMessage: string;
    previousControlledValue: any;
}

interface FieldInfo {
    value: any;
    checked: boolean;
    error: boolean;
    helperText: string;
    onChange: (event: any, checked?: boolean) => void;
}

export default class FormState {
    static create(formDefinition: FormDefinition) {
        return new FormState(formDefinition);
    }

    private readonly _formDefinition: FormDefinition;
    private _formStateReact: any;
    private readonly _setFormStateReact: any;
    private _onFormChange?: () => void;

    private constructor(formDefinition: FormDefinition) {
        var initialState: ReactFormDefinition = {};

        for(var fieldId in formDefinition) {
            if(formDefinition.hasOwnProperty(fieldId) && formDefinition[fieldId].value != null) {
                const controlledValue = formDefinition[fieldId].value;
                initialState[fieldId] = { value: controlledValue, errorMessage: '', previousControlledValue: controlledValue };
            }
        }

        // eslint-disable-next-line react-hooks/rules-of-hooks
        const [formStateReact, setFormStateReact] = React.useState<ReactFormDefinition>(initialState);

        this._formDefinition = formDefinition;
        this._formStateReact = formStateReact;
        this._setFormStateReact = setFormStateReact;

        this.updateControlledValues();
    }

    field(fieldId: string): FieldInfo {
        const _this = this;
        const field = this._formStateReact[fieldId];
        const fieldValue = (field?.value) || '';
        const errorMessage = (field?.errorMessage) || '';

        return {
            value: fieldValue,
            checked: fieldValue === true,
            error: errorMessage !== '',
            helperText: errorMessage,
            onChange: (event: any, checked?: boolean) => {
                let newValue = checked != null ? checked : event.target.value;
                _this.updateField(fieldId, newValue);
                _this.triggerOnFormChangeEvent();
            }
        };
    }

    /**
     * Validate form fields
     * @param fieldIds fields for validation, validates all fields if omitted
     */
    validate(...fieldIds: string[]): boolean {
        var validationResult = true;

        if(fieldIds == null || fieldIds.length === 0) {
            fieldIds = Object.keys(this._formDefinition);
        }

        for(const fieldId of fieldIds) {
            if(this._formDefinition.hasOwnProperty(fieldId)) {
                if(this._formStateReact.hasOwnProperty(fieldId)) {
                    this.updateField(fieldId, this._formStateReact[fieldId].value, true);
                }
                else {
                    this.updateField(fieldId, null, true);
                }

                if(this.field(fieldId).error) {
                    validationResult = false;
                }
            }
        }

        return validationResult;
    }

    getValues(): any {
        var values: { [key: string]: any} = {};

        for(var fieldId in this._formStateReact) {
            if(this._formStateReact.hasOwnProperty(fieldId)) {
                values[fieldId] = this._formStateReact[fieldId].value;
            }
        }

        return values;
    }

    validateAndProcessOnClick(processFormValues: (formValues: any) => void): (() => void) {
        return () => {
            if(this.validate()) {
                var formValues = this.getValues();
                processFormValues(formValues);
            }
        }
    }

    clearForm() {
        this._setFormStateReact({});
        this.triggerOnFormChangeEvent();
    }

    set onFormChange(action: () => void) {
        this._onFormChange = action;
    }

    private updateField(fieldId: string, newFieldValue: any, finalValidation: boolean = false) {
        const errorMessage = this.validateField(fieldId, newFieldValue, finalValidation);
        const newFormStateReact = { ...this._formStateReact, [fieldId]: {
            ...this._formStateReact[fieldId],
            value: newFieldValue,
            errorMessage: errorMessage
        }};

        this._setFormStateReact(newFormStateReact);
        this._formStateReact = newFormStateReact;
    }

    private validateField(fieldId: string, newFieldValue: any, finalValidation: boolean = false): string {
        const fieldDefinition = this._formDefinition[fieldId];
        const currentField = this._formStateReact[fieldId];
        const hasCurrentError = currentField?.errorMessage != null && currentField?.errorMessage !== '';

        if(fieldDefinition == null) {
            return '';
        }
        else if(fieldDefinition.required === true && (newFieldValue === '' || newFieldValue == null)) {
            return 'This field is required';
        }
        else if(fieldDefinition.maxLength != null && newFieldValue?.length != null && newFieldValue.length > fieldDefinition.maxLength) {
            return `This field can be max ${fieldDefinition.maxLength} characters long (exceeded by ${newFieldValue.length - fieldDefinition.maxLength})`;
        }
        else if((finalValidation || hasCurrentError) && fieldDefinition.minLength != null && newFieldValue?.length != null && newFieldValue.length < fieldDefinition.minLength) {
            return `This field must be at least ${fieldDefinition.minLength} characters long`;
        }
        else if((finalValidation || hasCurrentError) && fieldDefinition.regexFormat != null &&
                newFieldValue !== '' && newFieldValue != null && !fieldDefinition.regexFormat.test(newFieldValue)) {
            return 'This field has wrong format';
        }

        return '';
    }

    private triggerOnFormChangeEvent() {
        this._onFormChange?.();
    }

    private updateControlledValues() {
        var changedFormStateField: ReactFormDefinition = {};
        var updateNeeded = false;

        for(var fieldId in this._formDefinition) {
            if(this._formDefinition.hasOwnProperty(fieldId)) {
                var newControlledValue = this._formDefinition[fieldId].value;
                var previousControlledValue = this._formStateReact.hasOwnProperty(fieldId) ? this._formStateReact[fieldId].previousControlledValue : null;

                if(newControlledValue === undefined) newControlledValue = null;
                if(previousControlledValue === undefined) previousControlledValue = null;

                if(newControlledValue !== previousControlledValue) {
                    var fieldChanges = {
                        value: newControlledValue,
                        previousControlledValue: newControlledValue
                    }

                    changedFormStateField[fieldId] = {
                        ...this._formStateReact[fieldId],
                        ...fieldChanges
                    }
                    updateNeeded = true;
                }
            }
        }

        if(updateNeeded) {
            var newFormStateReact = {...this._formStateReact, ...changedFormStateField};
            this._setFormStateReact(newFormStateReact);
            this._formStateReact = newFormStateReact;
            this.triggerOnFormChangeEvent();
        }
    }
}
