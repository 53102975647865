import { RouteComponentProps } from 'react-router-dom';
import { MultistreamPlayer } from '@multistream/player';

type Props = RouteComponentProps<{
    id: string
}>;

const GamePlayer = (props: Props) => {
    const gameId = props.match.params.id;

    return (
        <MultistreamPlayer apiUrl={'/api/games/' + gameId} prefetchMultiVideoForGameId={gameId} />
    );
}

export default GamePlayer;
