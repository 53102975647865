import { SiteState } from '../types/siteState';
import { Notification } from '../types/notification';
import { SiteFlags } from '../types/siteFlags';
import { CardsData } from '../types/cardsData';

export const getCardsData = (siteState: SiteState, id: string): CardsData => {
    return siteState?.cards?.[id];
};

export const getNotifications = (siteState: SiteState): Array<Notification> => {
    return siteState?.notifications;
};

export const getSiteFlags = (siteState: SiteState): SiteFlags => {
    return siteState?.siteFlags;
};
