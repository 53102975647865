import React, { FunctionComponent } from 'react';
import { withStyles, createStyles, WithStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core';
import { createMultistreamUrl } from '../../utils/functions/playerUrlCreators';
import moment from 'moment';
import { Link as RouterLink } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';
import LockIcon from '@material-ui/icons/Lock';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import CardContent from '@material-ui/core/CardContent';
import CardActionArea from '@material-ui/core/CardActionArea';
import MultistreamCardMedia, { CardStream } from './MultistreamCardMedia';
import MultistreamCardRoot from './MultistreamCardRoot';
import UserMultistreamSettings from './UserMultistreamSettings';


const styles = (theme: Theme) => createStyles({
    footer: {
        marginTop: 8
    }
});

type UserMultistreamCardProps = WithStyles<typeof styles> & {
    keyId: string;
    title: string;
    description?: string;
    creationDate: string;
    isPrivate: boolean;
    lastVisitDate?: string;
    visits: number;
    streams: Array<CardStream>;
};

const UserMultistreamCard: FunctionComponent<UserMultistreamCardProps> = (props) => {
    const { classes } = props;
    const { keyId, title, description, creationDate, isPrivate, lastVisitDate, visits, streams } = props;

    const url = createMultistreamUrl(keyId, title);
    const friendlyCreationDate = moment.utc(creationDate).local().format('YYYY-MM-DD HH:mm');
    const friendlyLastVisitDate = lastVisitDate ? moment.utc(lastVisitDate).local().format('YYYY-MM-DD HH:mm') : '-';

    return (
        <Box m={2}>
            <MultistreamCardRoot title={title} description={description} streams={streams}>

                <Box display='flex' justifyContent='space-between'>
                    <Box pt={1.6} pl={2} style={{overflow: 'hidden', textOverflow: 'ellipsis'}}>
                        <Typography variant='h6' noWrap>
                            <b>{title && /\S/.test(title) ? title : <React.Fragment>&nbsp;</React.Fragment>}</b>
                        </Typography>
                    </Box>
                    <Box mt={0.5} mr={0.5}>
                        <UserMultistreamSettings multistreamKey={keyId} title={title} url={url} isPrivate={isPrivate} />
                    </Box>
                </Box>
                <Box pb={1} pl={2}>
                    <Typography variant='body1' noWrap>
                        {description && /\S/.test(description) ? description : <React.Fragment>&nbsp;</React.Fragment>}
                    </Typography>
                </Box>

                <Link component={RouterLink} to={url} color='inherit' underline='none'>
                    <CardActionArea>
                        <MultistreamCardMedia streams={streams} />
                        <CardContent>
                            <Typography className={classes.footer} variant='body2' color='textSecondary' noWrap>
                                Created: &nbsp;{friendlyCreationDate}
                            </Typography>
                            <Typography className={classes.footer} variant='body2' color='textSecondary' noWrap>
                                Last visit: {friendlyLastVisitDate}
                            </Typography>
                            <Box display='flex' justifyContent='space-between'>
                                <Typography className={classes.footer} variant='body2' color='textSecondary' noWrap>
                                    Visits: {visits}
                                </Typography>
                                <Box my={0.5}>
                                    {isPrivate ? <LockIcon /> : <LockOpenIcon />}
                                </Box>
                            </Box>
                        </CardContent>
                    </CardActionArea>
                </Link>
            </MultistreamCardRoot>
        </Box>
    );
}

export default withStyles(styles)(UserMultistreamCard);
