import React, { FunctionComponent } from 'react';
import { withStyles, createStyles, WithStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core';
import { connect, ConnectedProps } from 'react-redux';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { useAuth0 } from '@auth0/auth0-react';
import { useHistory } from 'react-router-dom';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DeleteIcon from '@material-ui/icons/Delete';
import LockIcon from '@material-ui/icons/Lock';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import DialogContentText from '@material-ui/core/DialogContentText';
import { changeMultistreamPrivacy, removeMultistream } from '../../actions/siteActions';

const styles = (theme: Theme) => createStyles({
    optionTitle: {
        marginLeft: theme.spacing(1)
    }
});

const mapDispatchToProps = {
    changeMultistreamPrivacy,
    removeMultistream
};

const connector = connect(null, mapDispatchToProps);

type SettingsOption = {
    title: string;
    icon: JSX.Element;
    action?: () => void;
    hidden?: () => boolean;
};

type UserMultistreamSettingsProps = WithStyles<typeof styles> & ConnectedProps<typeof connector> & {
    multistreamKey: string;
    title: string;
    url: string;
    isPrivate: boolean;
};

const UserMultistreamSettings: FunctionComponent<UserMultistreamSettingsProps> = (props) => {
    const { classes } = props;
    const { multistreamKey, title, url, isPrivate } = props;
    const { changeMultistreamPrivacy, removeMultistream } = props;

    const authContext = useAuth0();
    const history = useHistory();
    const [menuAnchorElement, setMenuAnchorElement] = React.useState(null);
    const [isConfirmationDialogOpen, setConfirmationDialogOpen] = React.useState(false);
    const isMenuOpen = Boolean(menuAnchorElement);

    const settingsOptions: Array<SettingsOption> = [
        { title: 'Open multistream', icon: <NavigateNextIcon />, action: () => history.push(url) },
        { title: 'Change to: Public', icon: <LockOpenIcon />, hidden: () => !isPrivate, action: () => changeMultistreamPrivacy(authContext, multistreamKey, false) },
        { title: 'Change to: Private', icon: <LockIcon />, hidden: () => isPrivate, action: () => changeMultistreamPrivacy(authContext, multistreamKey, true) },
        { title: 'Remove multistream', icon: <DeleteIcon />, action: () => setConfirmationDialogOpen(true) }
    ];

    const handleMenuOpenClick = (event: React.SyntheticEvent) => {
        setMenuAnchorElement(event.currentTarget);
    };

    const handleMenuClose = () => {
        setMenuAnchorElement(null);
    };

    const handleMenuItemClick = (settingsOption: SettingsOption) => {
        handleMenuClose();
        settingsOption.action?.();
    };

    const onConfirmationDialogClose = () => {
        setConfirmationDialogOpen(false);
    };

    const onRemoveConfirmation = () => {
        removeMultistream(authContext, multistreamKey);
    };

    return (
        <Box>
            <IconButton title='Settings' aria-label="settings" onClick={handleMenuOpenClick}>
                <MoreVertIcon />
            </IconButton>

            <Menu anchorEl={menuAnchorElement} open={isMenuOpen} onClose={handleMenuClose} keepMounted>
                {settingsOptions.map((settingOption, index) => (settingOption.hidden == null || !settingOption.hidden()) &&
                    <MenuItem key={index} onClick={() => handleMenuItemClick(settingOption)}>
                        {settingOption.icon}
                        <Typography className={classes.optionTitle}>{settingOption.title}</Typography>
                    </MenuItem>
                )}
            </Menu>

            <Dialog maxWidth='xs' open={isConfirmationDialogOpen} onClose={onConfirmationDialogClose}>
                <DialogTitle>Are you sure?</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        This will permanently remove multistream: <b>{title}</b>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onConfirmationDialogClose} color='secondary' variant='contained'>
                        Cancel
                    </Button>
                    <Button onClick={onRemoveConfirmation} color='primary' variant='contained'>
                        Remove
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default connector(withStyles(styles)(UserMultistreamSettings));
