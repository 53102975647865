import reduceReducers from 'reduce-reducers';
import { createReducer } from '@multistream/utils';
import { types } from '../actions/siteActions';
import { cardsReducer } from './cardsReducer';
import { Notification } from '../types/notification';
import { SiteState } from '../types/siteState';
import { SiteFlags } from '../types/siteFlags';

const initialState: SiteState = {
    cards: {},
    notifications: [],
    siteFlags: {}
};

const siteReducer = createReducer<SiteState>({
    [types.NOTIFY]: (draft, notification: Notification) => {
        draft.notifications.push(notification);
    },
    [types.REMOVE_NOTIFICATION]: (draft, key: string) => {
        draft.notifications = draft.notifications.filter(x => x.key !== key);
    },
    [types.UPDATE_SITE_FLAGS]: (draft, siteFlags: SiteFlags) => {
        draft.siteFlags = { ...draft.siteFlags, ...siteFlags };
    },
});


export default reduceReducers(initialState, siteReducer, cardsReducer);
