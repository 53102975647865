import { createReducer } from '@multistream/utils';
import { types } from '../actions/cardsActions';
import { CardsData } from '../types/cardsData';
import { SiteState } from '../types/siteState';

export const cardsReducer = createReducer<SiteState>({
    [types.UPDATE_CARDS]: (draft, payload: CardsData) => {
        if(!draft.cards[payload.id]) {
            draft.cards[payload.id] = payload;
        }
        else {
            if(draft.cards[payload.id].cards && payload.cards) {
                payload.cards = draft.cards[payload.id].cards.concat(payload.cards);
            }

            draft.cards[payload.id] = {
                ...draft.cards[payload.id],
                ...payload
            }
        }
    },
    [types.CLEAR_CARDS]: (draft, id: string) => {
        if(draft.cards[id]) {
            draft.cards[id].cards = null;
        }
    },
    [types.CLEAR_ALL_CARDS]: (draft) => {
        draft.cards = {};
    }
});
