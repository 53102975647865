import React, { FunctionComponent } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import { createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider } from '@material-ui/styles';
import { Route } from 'react-router-dom';
import Player from '../components/players/Player';
import GamePlayer from '../components/players/GamePlayer';

const playerTheme = createMuiTheme({
    palette: {
        background: {
            default: '#000000'
        }
    }
});


const PlayerApp: FunctionComponent = () => {
    return (
        <React.Fragment>
            <ThemeProvider theme={playerTheme}>
                <CssBaseline />
                <Route path={['/player/:key?', '/p/:key?']} component={Player} />
                <Route path={['/game/:id?', '/g/:id?']} component={GamePlayer} />
            </ThemeProvider>
        </React.Fragment>
    );
}

export default PlayerApp;
