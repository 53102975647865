import React, { FunctionComponent } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { withStyles, WithStyles, createStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Hidden from '@material-ui/core/Hidden';
import Link from '@material-ui/core/Link';
import { useAuth0 } from '@auth0/auth0-react';
import { ButtonProgress } from '@multistream/utils';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';

const styles = (theme: Theme) => createStyles({
    loginText: {
        maxWidth: '290px'
    },
    loginButton: {
        padding: theme.spacing(1, 1)
    }
});

type LoginButtonProps = WithStyles<typeof styles> & {};

const LoginButton: FunctionComponent<LoginButtonProps> = (props) => {
    const { classes } = props;
    const authContext = useAuth0();

    if (authContext.isAuthenticated) {
        return (
            <React.Fragment>
                <Hidden mdDown>
                    <Typography className={classes.loginText} variant='subtitle1' noWrap>
                        <Link className={classes.loginButton} component={RouterLink} to='/user' color='inherit' underline='none'>
                            Logged as {authContext.user.nickname}
                        </Link>
                    </Typography>
                </Hidden>
                <Hidden lgUp>
                    <IconButton title={`Logged as ${authContext.user.nickname}`} edge='end' component={RouterLink} to='/user' color='inherit' aria-label='Account'>
                        <AccountCircleIcon />
                    </IconButton>
                </Hidden>
            </React.Fragment>
        );
    }
    else {
        return (
            <Button color='inherit' className={classes.loginButton} onClick={authContext.loginWithPopup} disabled={authContext.isLoading}>
                Login
                {authContext.isLoading && <ButtonProgress />}
            </Button>
        );
    }
};

export default withStyles(styles)(LoginButton);
