import { RouteComponentProps } from 'react-router-dom';
import { MultistreamPlayer } from '@multistream/player';

type Props = RouteComponentProps<{
    key: string
}>;

const Player = (props: Props) => {
    const key = props.match.params.key;

    return (
        <MultistreamPlayer apiUrl={'/api/multistreams/' + key} />
    );
}

export default Player;
